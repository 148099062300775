var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"align":"center","align-content":"center"}},[_c('i18n',{staticClass:"ml-4 primary--text text-uppercase text-h4",attrs:{"path":'menu.tariff.'+_vm.$route.name.toLowerCase()}}),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[(_vm.alltypes)?_c('v-select',{attrs:{"items":_vm.$t('tariff_types'),"label":_vm.$t('fields.tariff_type'),"clearable":""},model:{value:(_vm.filter.type),callback:function ($$v) {_vm.$set(_vm.filter, "type", $$v)},expression:"filter.type"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.tags,"label":_vm.$t('fields.tags'),"clearable":""},model:{value:(_vm.filter.tag),callback:function ($$v) {_vm.$set(_vm.filter, "tag", $$v)},expression:"filter.tag"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-btn',{staticClass:"error ma-4",attrs:{"icon":"","dark":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-data-table',{staticClass:"transparent table-custom",attrs:{"headers":_vm.headers,"items":_vm.records,"search":_vm.search,"item-key":"idx","show-select":_vm.isSelectable,"footer-props":_vm.foot_props,"no-data-text":_vm.$t('label.nodata')},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.$store.getters['session/testPowerUser'])?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1):_vm._e()]}},{key:"item.days",fn:function(ref){
var item = ref.item;
return [(item.time)?_c('sc-week-days',{attrs:{"days":item.time.days}}):_vm._e()]}},{key:"item.hours",fn:function(ref){
var item = ref.item;
return [(item.time)?_c('span',[_vm._v(_vm._s(_vm._f("time_interval")(item.time.hours)))]):_vm._e()]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("duration_filter")(item.duration)))])]}},{key:"item.spend",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("spend_filter")(item.duration)))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("tarifftype")(item.type,_vm.$t("tariff_types"))))])]}},{key:"item.webtype",fn:function(ref){
var item = ref.item;
return [(item.webtype)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("currency")(item.price)))]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('sc-record-status',{attrs:{"status":item.info.status}})]}},{key:"footer.prepend",fn:function(){return [(_vm.$store.getters['session/testPowerUser'])?_c('v-btn',{staticClass:"pink my-1",attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.edit(null)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus")])],1):_vm._e(),(_vm.isSelectable)?_c('v-btn',{staticClass:"accept-btn success",attrs:{"fab":"","dark":""},on:{"click":_vm.saveTariffs}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-check")])],1):_vm._e()]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('sc-tariff-dialog',{attrs:{"item":_vm.item,"type":_vm.type},on:{"update:item":function($event){_vm.item=$event},"save":_vm.saveTariff},model:{value:(_vm.d_edit),callback:function ($$v) {_vm.d_edit=$$v},expression:"d_edit"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }